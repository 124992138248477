import React, { useRef } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import useStore from '../store/useStore'
import ContentHeader from '../components/Typography/ContentHeader'
import ContentText from '../components/Typography/ContentText'
import InnerLink from '../components/Typography/InnerLink'
import MediumImage from '../components/Image/MediumImage'
// import SectionStyle1 from '../components/curves/SectionStyle1'
import SEO from '../layouts/Seo'
import ImgWrapper from '../components/Image/ImgWrapper'
import ImgLightBox from '../components/lightbox/ImgLightBox'
import GeneralCarousel from '../contents/carousel/GeneralCarousel'

import HasirTelKullanim from '../contents/common/HasirtelKullanim'
const PanelTelPage = () => {
  const { getImageByName } = useStore()
  const lightBoxRef = useRef()
  return (
    <>
      <SEO
        title='Panel Tel İmalatı - Erez Kaynak'
        description='Panel Tel İmalatı Firmamız sıcak daldırma galvaniz , elektro galvaniz yada siyah ham çelik tel malzeme kullanarak panel tel imalatı yapmaktadır.Firmamız panel tellerini boyasız olarak imalatını yapmaktadır.Tel kalınlığı 2mm den 8mm tel kalınlığına kadar panel tel imalatını yapabilmekteyiz.'
        keywords='panel tel, fens teli,paslanmaz panel,tel panel,panel tel imalatı,güvenlik panel tel,bahçe çiti,panel çit,tel örgü,tel örgü fiyatları,çit teli,bahçe teli,panel çit fiyatları,tel çit fiyatları,çit,çit teli fiyatları,kümes teli,çit fiyatları,örgü tel fiyatları,bahçe teli fiyatları,panel tel fiyatları,puntalı panel fiyatları'
        url='panel-tel-imalati'
        imgurl={getImageByName('galvanizhasirtel5').sm.src}
      />
      <ImgLightBox ref={lightBoxRef} gallery={[getImageByName('galvanizhasirtel5').original.src]} />
      <Box paddingX={3} paddingY={5}>
        <Grid container spacing={6} alignItems='flex-start'>
          <Grid item xs={12} sm={12} md={6} lg={7} xl={7}>
            <ContentHeader title='Panel Tel İmalatı' description='Türkiyenin her yerine gönderim yapılmaktadır.' />
            <ContentText>
              <p>
                Firmamız <strong>sıcak daldırma galvaniz , elektro galvaniz ya da siyah ham çelik tel</strong> malzeme kullanarak{' '}
                <strong>panel tel</strong> imalatı yapmaktadır.
              </p>
              <p>
                Firmamız <strong>panel tellerini</strong> boyasız olarak imalatını yapmaktadır.
              </p>
              <p>
                Tel kalınlığı 2mm den 8mm tel kalınlığına kadar <strong>panel tel</strong> imalatını yapabiliyoruz.
              </p>
              <p>
                Göz aralığı dikdörtgen de olmak üzere istenilen her ölçüde <strong>panel tel</strong> imalatı yapılmaktadır.
              </p>
              <p>
                Özel göz aralıklarında (örn; 35x75mm vb.) yeni kalıp yaparak hızlı bir şekilde müşterilerimizin ihtiyacı olan{' '}
                <strong>panel tel</strong> uygun bir maliyet ile üretiyoruz.
              </p>
              <p>
                Panel tellere Elektrostatik toz fırın boya işlemi yapılabilmektedir. Standart yada özel renk seçenekleri ile hasır teller
                elektrostatik toz boya ile boyanmaktadır. Firmamız bünyesinde boyasız olarak hasır tel imalatı gerçekleştirip, çalışmış
                olduğumuz elektrostatik toz boya yapan firmalar ile ihtiyacınız karşılanmaktadır.
              </p>
              <p>Detaylı bilgi yada fiyat teklifi almak için bizi arayınız.</p>
            </ContentText>
            <Box textAlign='center'>
              <InnerLink title='İletişim Bilgilerimiz İçin Tıklayınız' url='/iletisim' />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
            <ImgWrapper onClick={() => lightBoxRef.current.openLightBox()}>
              <MediumImage lazyHeight={500} img={getImageByName('galvanizhasirtel5')} alt='Özel hasır tel imalatı' />
            </ImgWrapper>
            <Box mt={2}>
              <MediumImage lazyHeight={500} img={getImageByName('celikhasirolculeri')} alt='özel göz hasır tel imalatı erez kaynak' />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <GeneralCarousel />

      <HasirTelKullanim title='Panel Tel Kullanım Alanları' transparentBG />
    </>
  )
}

export default PanelTelPage
